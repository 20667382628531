<template>
  <div class="container">
    <div class="breadcrumb">
      <p class="topic_path m-0">
        <router-link :to="{ name: 'AccountSettings'}" class="ashen-link">{{
            $t("account.default.pageTitle")
          }}
        </router-link>
        <i class="fas fa-angle-right form-control-color"></i>
        {{ $t("account.editWithoutPassword.changeLatestPcrTestDate") }}
      </p>
    </div>
    <div class="card text-first border-0">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4 mt-sm-2">{{ $t("account.editWithoutPassword.changeLatestPcrTestDate") }}</h3>
          <div class="col">
            <div class="form-group m-t-15">
              <label>{{ $t("account.editWithoutPassword.latestPcrTestResult") }}</label>
              <div class="mt-2">
                <div class="form-check form-check-inline col">
                  <input id="inlineRadio14" v-model="latestPcrTestResult" class="form-check-input"
                         name="isSuspectInfected"
                         type="radio"
                         value="1">
                  <label class="form-check-label" for="inlineRadio14">{{
                      $t("account.editWithoutPassword.positive")
                    }}</label>
                </div>
                <div class="form-check form-check-inline col mx-lg-5">
                  <input id="inlineRadio15" v-model="latestPcrTestResult" class="form-check-input"
                         name="isSuspectInfected"
                         type="radio"
                         value="-1">
                  <label class="form-check-label" for="inlineRadio15">{{
                      $t("account.editWithoutPassword.negative")
                    }}</label>
                </div>
                <div class="form-check form-check-inline col mx-lg-4">
                  <input id="inlineRadio16" v-model="latestPcrTestResult" class="form-check-input"
                         name="isSuspectInfected"
                         type="radio"
                         value="0">
                  <label class="form-check-label" for="inlineRadio16">{{
                      $t("account.editWithoutPassword.notInspected")
                    }}</label>
                </div>
              </div>
              <div v-show="errors['latestPcrTestResult']" id="err_pcrTest" class="form-error">
                {{ errors['latestPcrTestResult'] }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="m-t-15 mb-2">
            {{ $t("account.editWithoutPassword.latestPcrTestDate") }}
          </label>
          <div class="input-group mb-3">
            <Calender :currentDate="latestPcrTestDate"
                      :disable="latestPcrTestResult == 0"
                      :type="'pcr'"
                      @update="updateDate"
            ></Calender>
          </div>
          <div v-show="errors['latestPcrTestDate']" id="err_lastInspectionDate" class="form-error">
            {{ errors['latestPcrTestDate'] }}
          </div>
        </div>
        <div class="row m-t-5">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn"
                    @click="$router.push('account')">
              {{ $t("account.editWithPassword.btnCancel") }}
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                    v-on:click="doChange">
              {{ $t("account.editWithPassword.btnChange") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SetUpdateUser} from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import {mapGetters} from "vuex";
import Calender from "../components/layouts/Calender";

export default {
  name: "PcrTestChange",
  components: {Calender},
  data() {
    return {
      latestPcrTestResult: '',
      latestPcrTestDate: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = this.latestPcrTestResult
      params.latestPcrTestDate = Common.dateFormatConvert(this.latestPcrTestDate)
      params.latestPcrTestUpdate = true

      return params
    },
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        const message = this.$t("account.editWithoutPassword.toastSuccessChangeLatestPcrTestResult")
        Common.showToast(message)
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.latestPcrTestResult = jsonObject.latestPcrTestResult
        this.latestPcrTestDate = Common.dateFormatConvertForDatePicker(jsonObject.latestPcrTestDate)
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    updateDate(date) {
      this.latestPcrTestDate = date
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.getUser()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getUser()
    }
  }
}
</script>

<style scoped>
.fa-angle-right {
  width: 15px;
  height: auto;
  color: #888888;
}

@media only screen and (max-width: 576px) {
  .topic_path {
    display: none;
  }

  .border-top {
    border: 0px !important;
  }
}

</style>
